<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Ingreso Extraordinario - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
               
                
                <b-col md="6">
                  <b-form-group>
                    <label>Cliente: <span @click="modalClients" class="text-info link">Nuevo</span></label>
                    <v-select placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="full_name" v-model="client" @search="SearchClients" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Comprobante :">
                    <b-form-select v-model="income.type_invoice" :options="invoices_type"></b-form-select>
                    <small v-if="errors.type_invoice" class="form-text text-danger" >Seleccione un comprobante</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Documento :">
                    <b-form-input type="text" ref="serie" @change="UpperCase" v-model="income.number"></b-form-input>
                    <small v-if="errors.serie"  class="form-text text-danger" >Ingrese una numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha Emisión:">
                    <b-form-input type="date" ref="date" v-model="income.broadcast_date"></b-form-input>
                    <small v-if="errors.broadcast_date" class="form-text text-danger">Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Caja:">
                    <b-form-input class="text-center" disabled v-model="box"></b-form-input>
                    <small v-if="errors.id_box" class="form-text text-danger">Seleccione una caja</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Medio de Pago: ">
                    <b-form-select  v-model="income.payment_method" :options="payment_method"></b-form-select>
                  </b-form-group>
                </b-col>
               

                <b-col md="2">
                  <b-form-group label="Moneda:">
                    <b-form-select ref="coin" v-model="income.coin" :options="coins"></b-form-select>
                    <small v-if="errors.coin" class="form-text text-danger" >Selccione un tipo de moneda</small>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Observación:">
                    <b-form-input type="text" v-model="income.observation"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Importe:">
                    <b-form-input type="number" class="text-right" step="any" v-model="income.total"></b-form-input>
                    <small v-if="errors.total" class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control btn-primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'ExtraordinaryIncome',
      role: 2,
      income: {
          id_user:'',
          id_client:'',
          id_box:'',
          linkage:[],
          type_invoice:'01',
          serie:'',
          number:'',
          broadcast_date:moment(new Date()).local().format("YYYY-MM-DD"),
          payment_method: '008',
          coin:'PEN',
          observation:'',
          total:(0).toFixed(2),
          state:1,
      },

      clients: [],
      client:null,

      invoices_type:[
        {value: "01", text : "Factura"},
        {value: "03", text : "Boleta de Venta"},
        {value: "04", text : "Liquidación de compra"},
        {value: "07", text : "Nota de crédito"},
        {value: "08", text : "Nota de débito"},
        {value: "09", text : "Guía de remisión - Remitente"},
        {value: "12", text : "Ticket o cinta emitido por máquina registradora"},
        {value: "22", text : "Comprobante por Operaciones No Habituales"},
        {value: "31", text : "Guía de Remisión - Transportista"},
        {value: "50", text : "Declaración Única de Aduanas - Importación definitiva"},
        {value: "52", text : "Despacho Simplificado - Importación Simplificada"},
        {value: "91", text : "Comprobante de No Domiciliado"},
        {value: "NE", text : "Nota de Entrada"},
        {value: "00", text : "Otros"},
      ],

      coins:[
        {value: "PEN", text : "Soles"},
        {value: "USD", text : "Dolares"},
        {value: "CLP", text : "Pesos Chilenos"},
      ],

      payment_method: [
          {value :"008", text :'EFECTIVO'},
          {value :"003", text :'TRANSFERENCIA DE FONDOS'},
          {value :"005", text :'TARJETA'},
          // {value :"006", text :'TARJETA DE CRÉDITO'},
          {value :"222", text :'YAPE'},
          {value :"333", text :'PLIN'},
      ],

      boxes:[],
      box:'',
      id_box:'',
      //errors
      errors: {
        id_client: false,
        type_invoice: false,
        id_box: false,
        number: false,
        broadcast_date: false,
        coin: false,
        total: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.GetBoxByUser();
    this.box = '';
    this.id_box = '';
  },
  methods: {
    GetBoxByUser,
    SearchClients,
    modalClients,
    UpperCase,

    AddIncome,
    Validate,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};


function SearchClients(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-clients/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.clients = response.data;
            loading(false);
      })
    }
}

function GetBoxByUser() {
  let me = this;
  let url = me.url_base + "box/get-box-by-user/"+me.user.id_user;
  axios({
    method: "GET",
    url:url,
    headers: { token: me.token, module: me.module, role: me.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.box = response.data.result.code + " | " + response.data.result.user;
      me.id_box = response.data.result.id_box;
    } else {
      me.id_box = '';
      me.box = '';
    }
  })
  .catch((error) => {
  });
}

function UpperCase() {
    this.income.number = this.income.number.toUpperCase();
}

function modalClients() {
  EventBus.$emit('ModalClientsShow');
}

function AddIncome() {
  let me = this;
  me.income.id_box = me.id_box;
  me.income.id_user = me.user.id_user;
  me.income.id_client = me.client.id;
  let url = me.url_base + "extraordinary-income/add";
  let data = me.income;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.income.id_user = '';
        me.income.id_client = '';
        me.income.id_box = '';
        me.income.type_invoice = '01';
        me.income.number = '';
        me.income.broadcast_date = moment(new Date()).local().format("YYYY-MM-DD");
        me.income.coin = 'PEN';
        me.income.payment_method = '008';
        me.income.observation = '';
        me.income.total = (0).toFixed(2);
        me.income.state = 1;
        me.client = null;

        me.GetBoxByUser();
        
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  this.errors.id_client = this.client == null || this.client == {}  ? true : false;
  this.errors.type_invoice = this.income.type_invoice.length == 0 ? true : false;
  this.errors.broadcast_date = this.income.broadcast_date.length == 0 ? true : false;
  this.errors.id_box = this.id_box.length == 0 ? true : false;
  this.errors.coin = this.income.coin.length == 0 ? true : false;
  this.errors.total = this.income.total.length == 0 ? true : false;
  

  if (this.errors.id_client) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.type_invoice) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.broadcast_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_box) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.coin) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar el ingreso ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddIncome();
    }
  });
}
</script>
